import truncateToTwoDigits from './truncateToTwoDigits';

const abbrInt = (num, toFloat = true, withRemainer = true) => {
  let isNegative = num < 0;
  num = Math.abs(num);
  if ( num < 1000 ){ return `${isNegative ? '-' : ''}${ toFloat ? truncateToTwoDigits(num,2) : Number(truncateToTwoDigits(num)) }`; }
  else if ( num >= 1000 && num < 1000000){
    let neg= isNegative ? '-' : '';
    let k= +(Math.floor(num/1000).toFixed());
    let r= (num-(k*1000)).toString();
    r= r.padStart(3,'0');
    r= r.slice(0,1);
    r= (r==='0' || withRemainer===false ? '' : '.'+r);
    return `${neg}${k}${r}k`;
  }
  else if ( num >= 1000000 && num < 1000000000){
    let neg= isNegative ? '-' : '';
    let m= +(Math.floor(num/1000000).toFixed());
    let r= (num-(m*1000000)).toString();
    r= r.padStart(6,'0');
    r= r.slice(0,2);
    r= (r==='0' || withRemainer===false ? '' : '.'+r);
    return `${neg}${m}${r}m`;
  }
  else if ( num >= 1000000000 && num < 1000000000000){
    let neg= isNegative ? '-' : '';
    let b= +(Math.floor(num/1000000000).toFixed());
    let r= (num-(b*1000000000)).toString();
    r= r.padStart(9,'0');
    r= r.slice(0,2);
    r= (r==='0' || withRemainer===false ? '' : '.'+r);
    return `${neg}${b}${r}b`;
  }
  else if ( num >= 1000000000000){
    let neg= isNegative ? '-' : '';
    let t= +(Math.floor(num/1000000000000).toFixed());
    let r= (num-(t*1000000000000)).toString();
    r= r.padStart(12,'0');
    r= r.slice(0,2);
    r= (r==='0' || withRemainer===false ? '' : '.'+r);
    return `${neg}${t}${r}t`;
  }
 };
export default abbrInt;