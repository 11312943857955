import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import config from "../config.json";
import decodeJwt from "../bin/decodeJwt";
import unauthorizedLevel from "../bin/unauthorizedLevel";
import authorizedLevel from "../bin/authorizedLevel";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import returnOptionTicker from "../bin/returnOptionTicker";
import Headers from "../bin/Headers";
import abbrInt from "../bin/abbrInt";
import LOADING from "../components/LOADING";
import OPTION_TRADE_ORDER_MODAL from './OPTION_TRADE_ORDER_MODAL';
import { BeakerIcon, BanknotesIcon } from '@heroicons/react/20/solid';

const MAIN = (props) => {
	let ticker = props.ticker,
		token = props.token,
		lastTrade = props.lastTrade,
		optionStrikeVolume = props.optionStrikeVolume,
		optionSnapshot = props.optionSnapshot,
		optionChains = props.optionChains,
		optionVolume = props.optionVolume,
		optionExpirations = props.optionExpirations,
		decodedToken = decodeJwt(token),
		[historyLookback, setHistoryLookback] = useState(90),
		[view, setView] = useState('value'),
		[rangeBound, setRangeBound] = useState(false),
		strikeTable = useRef(null),
		initialScrollToPrice = useRef(false),
		[snapshots, setSnapshots] = useState({}),
		[loading, setLoading] = useState(false),
		[orderTradeData, updateOrderTradeData] = useState(null),
		[showOptionTradeModal, updateShowOptionTradeModal]= useState(false),
		optionPremiumHistory = useRef(null),
		optionExpiration = useRef(null),
		chartRef = useRef(null);
	
	const loadOptionValueUI = (optionDetails) => {
		if (!optionDetails || !Object.keys(optionDetails).length) { return; }
		let chainMapByExpiration = optionDetails;
		let highestBinaryCalls = null;
		let highestBinaryPuts = null;
		for (let key of Object.keys(chainMapByExpiration)) {
			if (!highestBinaryCalls || highestBinaryCalls.premium<chainMapByExpiration[key].BinaryCallPremium) { highestBinaryCalls = { expiry: key, premium: chainMapByExpiration[key].BinaryCallPremium }; }
			if (!highestBinaryPuts || highestBinaryPuts.premium<chainMapByExpiration[key].BinaryPutPremium) { highestBinaryPuts = { expiry: key, premium: chainMapByExpiration[key].BinaryPutPremium }; }
		}
		
		return (Object.keys(chainMapByExpiration)).map((expiry, idx) => {
			let highestSide = 'hedge';
			let binaryCallPercent = chainMapByExpiration[expiry].BinaryCallPremium ? Math.abs(Math.trunc((Number(chainMapByExpiration[expiry].BinaryCallPremium) / Number(chainMapByExpiration[expiry].Premium)) * 100)) : 0;
			let binaryPutPercent = chainMapByExpiration[expiry].BinaryPutPremium ? Math.abs(Math.trunc((Number(chainMapByExpiration[expiry].BinaryPutPremium) / Number(chainMapByExpiration[expiry].Premium)) * 100)) : 0;
			let nonBinaryPercent = 100 - binaryCallPercent - binaryPutPercent;
			let nonBinaryTotal = Number(chainMapByExpiration[expiry].Premium) - (Number(chainMapByExpiration[expiry].BinaryCallPremium) + Number(chainMapByExpiration[expiry].BinaryPutPremium));
			if (Number(nonBinaryTotal) > Number(chainMapByExpiration[expiry].BinaryCallPremium) && Number(nonBinaryTotal) > Number(chainMapByExpiration[expiry].BinaryPutPremium)) {
				highestSide = 'hedge';
			} else if (Number(chainMapByExpiration[expiry].BinaryCallPremium) > Number(nonBinaryTotal) && Number(chainMapByExpiration[expiry].BinaryCallPremium) > Number(chainMapByExpiration[expiry].BinaryPutPremium)) {
				highestSide = 'calls';
			} else if (Number(chainMapByExpiration[expiry].BinaryPutPremium) > Number(nonBinaryTotal) && Number(chainMapByExpiration[expiry].BinaryPutPremium) > Number(chainMapByExpiration[expiry].BinaryCallPremium)) {
				highestSide = 'puts';
			}

			return <div key={idx} className={`flex flex-row w-full text-xxs sm:text-xs ${idx % 2 === 0 ? '' : 'bg-gray-800'}`}>
				<span className="px-1 py-2 items-start sm:items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 font-medium uppercase tracking-wider w-20 flex flex-col sm:flex-row flex-grow">
					<span className='flex w-2/3'>
						{decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') ?
							<a href={`/t/${ticker}`} className='hover:underline'>{expiry}</a>
							:
							<span>{expiry}</span>
						}
					</span>
					<span className='flex flex-row justify-between w-full text-xxs sm:mx-1 rounded-lg'>
						<span style={{ width: `${binaryCallPercent}%` }} className={`bg-emerald-700 flex items-center justify-start rounded-l-lg text-white pl-2 font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{binaryCallPercent}%</span></span>
						<span style={{ width: `${nonBinaryPercent}%` }} className={`bg-sky-700 flex items-center justify-center text-white font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{nonBinaryPercent}%</span></span>
						<span style={{ width: `${binaryPutPercent}%` }} className={`bg-red-700 flex items-center justify-end rounded-r-lg text-white pr-2 font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{binaryPutPercent}%</span></span>
					</span>
				</span>
				<span className={`p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-green-300 ${highestBinaryCalls && highestBinaryCalls.expiry && highestBinaryCalls.expiry===expiry ? 'bg-emerald-800' : ''}`}>
					<span>${abbrInt(chainMapByExpiration[expiry].BinaryCallPremium, false)}</span>
				</span>
				<span className="p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-sky-300">
					<span>${abbrInt(nonBinaryTotal, false)}</span>
				</span>
				<span className={`p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-red-300 ${highestBinaryPuts && highestBinaryPuts.expiry && highestBinaryPuts.expiry === expiry ? 'bg-rose-800' : ''}`}>
					<span>${abbrInt(chainMapByExpiration[expiry].BinaryPutPremium, false)}</span>
				</span>
			</div>;
		});
	};
	
	const findClosestNumber = (target, arr) => {
		const numArray = arr.map(Number);
		let closestNum = numArray[0];
		for (let i = 1; i < numArray.length; i++) {
			if (Math.abs(target - closestNum) > Math.abs(target - numArray[i])) {
				closestNum = numArray[i];
			}
		}
		return closestNum;
	};

	function VisualBar({ value, maxValue, color, align }) {
		const validValue = value && !isNaN(value) ? Number(value) : 0;
		const percentage = (validValue / maxValue) * 100;
		return (
			<div className="flex flex-row h-2 items-center justify-center w-full gap-0.5">
				<span className={`text-[8px] w-[30px] ${align === 'right' ? 'order-last text-right' : 'text-left'}`}>{abbrInt(value, false)}</span>
				<div className="relative w-full h-1 bg-transparent">
					<div className={`absolute top-0 h-full ${align==='right' ? 'right-0' : (align==='left' ? 'left-0' : '')}`} style={{ width: `${percentage}%`, backgroundColor: color, }} />
				</div>
			</div>
		);
	}

	const loadOptionVolumeUI = (data) => {
		if (!data || !Object.keys(data).length) { return; }
		data = (Object.keys(data)).reduce((acc, Strike) => {
			let entry = {Strike, ...data[Strike]};
			return [...acc, entry];
		}, []).sort((a, b) => Number(a.Strike) - Number(b.Strike));

		let maxCallVolume = Math.max(...data.map(d => d.Calls ? Number(d.Calls) : 0));
		let maxPutVolume = Math.max(...data.map(d => d.Puts ? Number(d.Puts) : 0));
		let maxBullish = Math.max(...data.map(d => d.Bullish ? Number(d.Bullish) : 0));
		let maxBearish = Math.max(...data.map(d => d.Bearish ? Number(d.Bearish) : 0));
		let maxValue = Math.max(maxCallVolume, maxPutVolume, maxBullish, maxBearish);
		
		let closestStrike = null;
		let last = lastTrade ? lastTrade : null;
		if (last && data && data.length) {
			let strikePrices = data.map(d => d.Strike).sort((a, b) => Number(a) - Number(b));
			closestStrike = findClosestNumber(last, strikePrices);
			closestStrike = closestStrike ? closestStrike.toString() : null;
		}
		// console.log(last, closestStrike);

		let FilterRangeBound = .1; //percent deviation
		let PositiveThreshold = last && FilterRangeBound ? truncateToTwoDigits(last * (FilterRangeBound + 1)) : null;
		let NegativeThreshold = last && FilterRangeBound ? truncateToTwoDigits(last * ((FilterRangeBound - 1) * -1)) : null;
		

		let contrastOnEven = 0;
		return data.map((entry, idx) => {
			let sentimentColor = '';
			let sentimentTextColor = '';
			let mostBullish = false;
			let mostBearish = false;
			let mostPutVolume = false;
			let mostCallVolume = false;
			if (entry.Bullish > entry.Bearish) { sentimentColor = 'bg-emerald-950/40'; sentimentTextColor = 'text-emerald-300'; }
			if (entry.Bearish > entry.Bullish) { sentimentColor = 'bg-rose-950/40'; sentimentTextColor = 'text-rose-300'; }
			if (maxBullish === entry.Bullish) { mostBullish = true; }
			if (maxBearish === entry.Bearish) { mostBearish = true; }
			if (maxCallVolume === entry.Calls) { mostCallVolume = true; }
			if (maxPutVolume === entry.Puts) { mostPutVolume = true; }

			if (closestStrike && entry.Strike === closestStrike) {
				entry.highlight = true;
			} else {
				entry.highlight = false;
			}

			if (PositiveThreshold && NegativeThreshold) {
				if (last && !isNaN(last) && entry.Strike <= PositiveThreshold && entry.Strike >= NegativeThreshold) {
					entry.bounds = true;
				} else {
					entry.bounds = false;
				}
			}

			if (rangeBound && !entry.bounds) {
				return <span key={entry.Strike}></span>;
			}
			++contrastOnEven;

			let lastCallPrice = null;
			let lastPutPrice = null;
			if (optionSnapshot && optionExpiration && optionExpiration.current) {
				let callOptionTicker = returnOptionTicker({ underlyingTicker: ticker, optionExpiration: optionExpiration.current, optionStrike: entry.Strike, optionType: 'CALL' });
				let putOptionTicker = returnOptionTicker({ underlyingTicker: ticker, optionExpiration: optionExpiration.current, optionStrike: entry.Strike, optionType: 'PUT' });
				if (optionSnapshot[callOptionTicker] && optionSnapshot[callOptionTicker].hasOwnProperty('Last')) { lastCallPrice = optionSnapshot[callOptionTicker].Last; }
				if (optionSnapshot[putOptionTicker] && optionSnapshot[putOptionTicker].hasOwnProperty('Last')) { lastPutPrice = optionSnapshot[putOptionTicker].Last; }
			}

			return <div key={entry.Strike} data-highlight={`${entry.highlight ? true : false}`} className={`grid grid-cols-custom w-full text-xxs sm:text-xs ${contrastOnEven % 2 === 0 ? '' : 'bg-gray-800'}`}>
				{authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
					<button
						onClick={() => { updateOrderTradeData({ underlyingTicker: ticker, optionType: "CALL", optionStrike: entry.Strike }); updateShowOptionTradeModal(true) }}
						className={`relative border ${mostCallVolume ? 'bg-amber-100 text-black' : 'text-white bg-gradient-to-r from-emerald-950/50'} border-gray-600 border-t-0 hover:cursor p-1 flex flex-col gap-0 items-center justify-center sm:font-medium sm:uppercase sm:tracking-wider`}>
						{lastCallPrice ? <span className='absolute left-0 text-[8px] z-50 bg-amber-800/60 px-0.5 w-[40px]'>${ lastCallPrice }</span> : <></>}
						<VisualBar value={entry.Calls} maxValue={maxValue} color={'#00BDE8'} align='right' />
						<VisualBar value={entry.Bullish} maxValue={maxValue} color={'#2AE800'} align='right' />
					</button>
					:
					<span className={`relative p-1 flex flex-col gap-0 items-center justify-center border ${mostCallVolume ? 'border-sky-300' : 'border-gray-700 border-t-0'} sm:font-medium sm:uppercase sm:tracking-wider`}>
						<VisualBar value={entry.Calls} maxValue={maxValue} color={'#00BDE8'} align='right' />
						<VisualBar value={entry.Bullish} maxValue={maxValue} color={'#2AE800'} align='right' />
					</span>
				}
				<span className={`p-1 relative items-center justify-center border ${entry.highlight ? 'border-amber-400' : 'border-gray-700 border-t-0'} sm:font-medium sm:uppercase sm:tracking-wider w-[100px] flex ${sentimentColor}`}>
					{mostBullish ? <span className='absolute left-0'>🟢</span> : ''}
					{mostBearish ? <span className='absolute right-0'>🔴</span> : ''}
					{decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') ?
						<a href={`/v/${ticker}`} className={`${(sentimentTextColor ? sentimentTextColor : '')}`}>${entry.Strike}</a>
						:
						<span className={`${(sentimentTextColor ? sentimentTextColor : '')}`}>${entry.Strike}</span>
					}
				</span>
				{authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
					<button
						onClick={() => { updateOrderTradeData({ underlyingTicker: ticker, optionType: "PUT", optionStrike: entry.Strike }); updateShowOptionTradeModal(true) }}
						className={`relative border ${mostPutVolume ? 'bg-amber-200 text-black' : 'text-white bg-gradient-to-l from-rose-950/50'} border-gray-600 border-t-0 hover:cursor p-1 flex flex-col gap-0 items-center justify-center sm:font-medium sm:uppercase sm:tracking-wider`}>
						{lastPutPrice ? <span className='absolute right-0 text-[8px] z-50 bg-amber-800/60 px-0.5 w-[40px]'>${ lastPutPrice }</span> : <></>}
						<VisualBar value={entry.Puts} maxValue={maxValue} color={'#00BDE8'} align='left' />
						<VisualBar value={entry.Bearish} maxValue={maxValue} color={'#E81100'} align='left' />
					</button>
					:
					<span className={`relative p-1 flex flex-col gap-0 items-center justify-center border ${mostPutVolume ? 'border-sky-300' : 'border-gray-700 border-t-0'} sm:font-medium sm:uppercase sm:tracking-wider`}>
						<VisualBar value={entry.Puts} maxValue={maxValue} color={'#00BDE8'} align='left' />
						<VisualBar value={entry.Bearish} maxValue={maxValue} color={'#E81100'} align='left' />
					</span>
				}
			</div>;
		});
	};

	const calculateOptionVolume = (data, type) => {
		if (!data || !Object.keys(data).length) { return; }
		let total = 0;
		let sentiment = 0;
		for (let key of Object.keys(data)) {
			total += Number(data[key][type]);
			if (type === 'Calls') { sentiment += Number(data[key].Bullish); }
			if (type === 'Puts') { sentiment += Number(data[key].Bearish); }
		}
		let maxValue = optionVolume ? Number(optionVolume) : 0;
		let sentimentPercentOfMaxValue = maxValue && sentiment ? Math.abs(Math.trunc((sentiment / maxValue) * 100)) : 0;
		return <span className={`w-full flex flex-col gap-1 item-center gap-1 justify-${type === 'Calls' ? 'end' : (type === 'Puts' ? 'start' : 'center')}`}>
			{/* <span className='text-xs'>{abbrInt(total, false)}</span><span className={`text-[9px] text-amber-800 rounded px-1 bg-amber-400 ${type==='Puts' ? 'order-first' : ''}`}>{abbrInt(sentiment, false)}</span> */}
			<span className={`text-[9px] ${sentiment>50 ? 'border border-amber-500 border-2' : ''} ${type==='Calls' ? 'text-emerald-800 bg-emerald-400' : (type==='Puts' ? 'text-rose-800 bg-rose-400' : 'text-amber-800 bg-amber-400')} rounded px-1 ${type === 'Puts' ? 'text-right' : (type === 'Calls' ? 'text-left' : '')}`}>{sentimentPercentOfMaxValue}%</span>
			<VisualBar value={total} maxValue={maxValue} color={'#00BDE8'} align={type === 'Calls' ? 'right' : (type === 'Puts' ? 'left' : 'center')} />
			<VisualBar value={sentiment} maxValue={maxValue} color={type === 'Calls' ? '#2AE800' : (type === 'Puts' ? '#E81100' : '#00BDE8')} align={type === 'Calls' ? 'right' : (type === 'Puts' ? 'left' : 'center')} />
		</span>;
	}

	const loadChart = (_historyLookback) => {
		let reload = false;
		if (_historyLookback) {
			if (_historyLookback === historyLookback) { return; }
			reload = true;
			setHistoryLookback(_historyLookback);
		} else {
			_historyLookback = historyLookback;
		}
		if (!optionPremiumHistory.current || !optionPremiumHistory.current.length) { return; }
		let _optionPremiumHistory = [...optionPremiumHistory.current];
		if (_historyLookback === 'all') { _optionPremiumHistory = _optionPremiumHistory.reverse(); }
		if (_historyLookback === 90) { _optionPremiumHistory = _optionPremiumHistory.slice(0, 90).reverse(); }
		if (_historyLookback === 180) { _optionPremiumHistory = _optionPremiumHistory.slice(0, 180).reverse(); }
		if (_historyLookback === 365) { _optionPremiumHistory = _optionPremiumHistory.slice(0, 365).reverse(); }
		// Dispose previous chart instance before setting new options
		if (reload && chartRef.current && chartRef.current.getEchartsInstance) {
			const chartInstance = chartRef.current.getEchartsInstance();
			chartInstance.dispose();
		}
		setLoading(false);
		if (_optionPremiumHistory.length > 0) {
			setSnapshots({
				title: {
					text: 'Option Premium History',
					// text: 'Option Trade History',
					textStyle: {
						color: '#ffffff'
					}
				},
				tooltip: {},
				xAxis: {
					// data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					data: _optionPremiumHistory.map(d => d.IndexDate)
				},
				yAxis: {},
				series: [{
					type: 'line',
					data: _optionPremiumHistory.map(d => d.OptionPremium)
					// data: _optionPremiumHistory.map(d => d.OptionTrades)
				}]
			});
		}
	};

	const updateHighlightedStrike = (scrollToOverride=false) => {
		if (!lastTrade) { return; }
		//check to see if strikeTable is currently visible

		if (scrollToOverride) {
			if (decodedToken && decodedToken.level && authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond')) {
				setRangeBound(true);
			}
		}

		if ((!initialScrollToPrice || initialScrollToPrice.current === true) && !scrollToOverride) { return; }
		if (strikeTable.current) {
			const highlightElement = strikeTable.current.querySelector("[data-highlight='true']");
			if (highlightElement) {
				const rect = highlightElement.getBoundingClientRect();
				const containerRect = getElementPositionInContainer(highlightElement, strikeTable.current);
				// Calculate the position of the element relative to the container
				const relativeTop = containerRect.top;
				// Calculate the vertical center position
				const centeredTop = relativeTop - (strikeTable.current.clientHeight / 2) + (rect.height / 2);
				// Scroll to the element
				strikeTable.current.scrollTop = centeredTop;
				initialScrollToPrice.current = true;
			}
		}
	};

	function getElementPositionInContainer(element, container) {
		const elemRect = element.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();
		const topPosition = elemRect.top - containerRect.top + container.scrollTop;
		const leftPosition = elemRect.left - containerRect.left + container.scrollLeft;
		return { top: topPosition, left: leftPosition };
	}

	const start = async () => {
		if (!token) { return; }
		let _optionPremiumHistory = await fetch(`${config.api}/optionpremium?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.result ? d.result : []);
		optionPremiumHistory.current = _optionPremiumHistory;
		loadChart();
	};

	useEffect(() => {
		if (!optionChains || !Object.keys(optionChains).length) { setLoading(false); return; }
		start();
	}, [optionChains]);

	//set the default expiration date to the first expiration date
	useEffect(() => {
		if (!optionExpirations) { return; }
		if (optionExpiration.current) { return; }
		let _optionExpirations = optionExpirations && Object.keys(optionExpirations).length ? Object.keys(optionExpirations) : [];
		_optionExpirations = _optionExpirations.sort((a, b) => new Date(a) - new Date(b));
		let defaultExpiration = optionExpiration;
		if (_optionExpirations && _optionExpirations.length) {
			defaultExpiration = _optionExpirations[0];
			optionExpiration.current = defaultExpiration;
		}
	}, [optionExpirations]);

	useLayoutEffect(() => { updateHighlightedStrike(); }, [lastTrade]);
	useEffect(() => {
		// console.log(optionSnapshot);
	}, [optionSnapshot]);

	
  return (
		<div className={`w-full h-full flex flex-col rounded-tr-md rounded-md border bg-gray-200 text-black justify-center`}>

			<div className="border-gray-300 rounded-t-lg w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5">
				<span className='flex flex-row items-center justify-end w-full'>
					{!loading ?
						<span className="flex-row inline-flex items-between justify-end flex-grow sm:items-center gap-1">
							<span className='inline-flex flex-grow justify-start items-center gap-1'>
								<button type="button" onClick={() => { setView('value'); }} className={`${!token ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${view === 'value' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									Expirations
								</button>
								<button type="button" onClick={() => { setView('strikes'); updateHighlightedStrike(true); }} className={`${!token ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${view === 'strikes' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									Strikes
								</button>
							</span>
							<span className='inline-flex w-full justify-end items-center gap-1'>
								<button
									type="button"
									onClick={() => { setView('graph'); }}
									className={`${!token ? 'hidden' : ''} rounded px-2 py-0.5 text-xs font-semibold border ${view === 'graph' ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
									<BeakerIcon className='w-5 h-4' />
								</button>
								{authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') && view==='strikes' ?
									<button
										type="button"
										onClick={() => { setRangeBound(!rangeBound); }}
										className={`rounded px-2 py-0.5 text-xs font-semibold border ${rangeBound ? 'border-gray-700 text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
										in range
									</button>
									:
									<></>
								}
							</span>
						</span>
						:
						<></>
					}
				</span>
			</div>
			
			<div className="col-span-2 flex flex-grow flex-col rounded-br-md rounded-bl-md border bg-gray-950 text-gray-200">
				{loading === true && <div className="h-100 flex items-center justify-center py-8 text-center"><LOADING color="text-green-500" /></div>}
				{(!loading && (!optionChains || !Object.keys(optionChains) || !Object.keys(optionChains).length)) && <div className="h-full flex items-center justify-center py-8 text-center">no options</div>}

				{!loading && view==='value' && Object.keys(optionChains) && Object.keys(optionChains).length > 0 ?
					<div className="h-full w-full">
						<div className='flex flex-row w-full text-[7px] sm:text-xs'>
							<span className="p-1 items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-20 flex flex-grow">Expiration</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Binary Calls</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Hedging</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Binary Puts</span>
						</div>
						<div className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full">
							{loadOptionValueUI(optionExpirations)}
						</div>
					</div>
					:
					<></>
				}
				{!loading && view==='strikes' && Object.keys(optionStrikeVolume) && Object.keys(optionStrikeVolume).length > 0 ?
					<div className="h-full w-full">
						<div className='grid grid-cols-custom w-full text-[7px] sm:text-xs'>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider">Call Volume/Sentiment</span>
							<span className="p-1 items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-[100px] flex">Strike</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider">Put Volume/Sentiment</span>
						</div>
						<div className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full" ref={strikeTable}>
							{loadOptionVolumeUI(optionStrikeVolume)}
						</div>
						<div className='grid grid-cols-2 w-full text-[7px] sm:text-xs bg-sky-950'>
							<span className="p-1 flex flex-col items-center justify-center border border-sky-700 border-t-1 sm:font-medium sm:uppercase sm:tracking-wider">
								{calculateOptionVolume(optionStrikeVolume, 'Calls')}
							</span>
							{/* <span className="p-1 items-center justify-center border border-sky-700 border-t-1 sm:font-medium sm:uppercase sm:tracking-wider w-[100px] flex">&nbsp;</span> */}
							<span className="p-1 flex flex-col items-center justify-center border border-sky-700 border-t-1 sm:font-medium sm:uppercase sm:tracking-wider">
								{calculateOptionVolume(optionStrikeVolume, 'Puts')}
							</span>
						</div>
					</div>
					:
					<></>
				}
				{!loading && view==='graph' && snapshots && Object.keys(snapshots).length > 0 ?
					<div className="h-full w-full text-white">
						<ReactECharts ref={chartRef} option={snapshots} />
						<div className='mx-5 my-2 flex flex-row items-center justify-end gap-2'>
							<button onClick={()=>{loadChart('all');}} className={`${historyLookback==='all' ? `bg-orange-700` : `bg-sky-900`} w-15 rounded px-5 text-xxs`}>all</button>
							<button onClick={()=>{loadChart(365);}} className={`${historyLookback===365 ? `bg-orange-700` : `bg-sky-900`} w-15 rounded px-5 text-xxs`}>365d</button>
							<button onClick={()=>{loadChart(180);}} className={`${historyLookback===180 ? `bg-orange-700` : `bg-sky-900`} w-15 rounded px-5 text-xxs`}>180d</button>
							<button onClick={()=>{loadChart(90);}} className={`${historyLookback===90 ? `bg-orange-700` : `bg-sky-900`} w-15 rounded px-5 text-xxs`}>90d</button>
						</div>
					</div>
					:
					<></>
				}
			</div>

			<OPTION_TRADE_ORDER_MODAL
				open={showOptionTradeModal}
				data={orderTradeData}
				optionSnapshot={optionSnapshot}
				optionExpirations={optionExpirations}
				setOpen={() => { updateOrderTradeData(null); updateShowOptionTradeModal(false); }}
				/>
		</div>
  );
}
export default MAIN;