import config from "../config.json";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MODAL from "../components/MODAL";
import VOLUME_MODAL from "../components/VOLUME_MODAL";
import displayTradeCompressedReturnJSX from "../bin/displayTradeCompressedReturnJSX";
import decodeJwt from "../bin/decodeJwt";
import displayChangeInPercentJSX from "../bin/displayChangeInPercentJSX";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import unauthorizedLevel from "../bin/unauthorizedLevel";
import authorizedLevel from "../bin/authorizedLevel";
import displayColorSignedPercentJSX from "../bin/displayColorSignedPercentJSX";
import calculateChangeInPercent from "../bin/calculateChangeInPercent";
import TICKERS_WITH_SAME_SIC_FOR_MODAL from './TICKERS_WITH_SAME_SIC_FOR_MODAL';
import TICKER_EXCHANGE_VOLUME_DATA_AS_STRING_FOR_MODAL from './TICKER_EXCHANGE_VOLUME_DATA_AS_STRING_FOR_MODAL';
import SHARE_TRADE_ORDER_MODAL from './SHARE_TRADE_ORDER_MODAL';
import compileTradeDetailForModal from "../bin/compileTradeDetailForModal";
import abbrInt from "../bin/abbrInt";
import Headers from "../bin/Headers";
import signedCurrency from "../bin/signedCurrency";
import capitalizeString from "../bin/capitalizeString";
import formatStockName from "../bin/formatStockName";
import exchanges from "../bin/exchanges";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL = (props) => {
  let tickerDetails = props.tickerDetails,
		tickerStats = props.tickerStats,
		lastTrade = props.lastTrade,
		delayedData = props.delayedData,
		showTickerInfo = props.showTickerInfo,
		optionOpenInterestValue = props.optionOpenInterestValue,
		showStockNotionalChart = props.showStockNotionalChart ? props.showStockNotionalChart : ()=>{},
		showOptionNotionalChart = props.showOptionNotionalChart ? props.showOptionNotionalChart : ()=>{},
		market = props.market,
		[orderTradeData, updateOrderTradeData]= useState(null),
		[tickersBySicData, updateTickersBySicData]= useState(null),
		[showTickersBySic, updateShowTickersBySic]= useState(false),
		[showShareTradeModal, updateShowShareTradeModal]= useState(false),
		[showVolumeInfo, updateShowVolumeInfo]= useState(false),
		[showVolumePriceBasedInfo, updateShowVolumePriceBasedInfo]= useState(false),
		[showTradeInfo, updateShowTradeInfo]= useState(false),
		[tradeDetail, updateTradeDetail] = useState(null),
		token= useSelector(state => state.token),
		decodedToken = decodeJwt(token);

	const calculatePercentageDifference = (num1, num2)=>{
		let largerNum = num1 > num2 ? num1 : num2;
		let smallerNum = num1 < num2 ? num1 : num2;
	
		let percentage = ((largerNum / smallerNum) - 1) * 100;
		return `${percentage.toFixed(2)}%`;
	};
	const whichSideIsHigher = (calls, puts)=>{
		if (calls > puts){
			return <span className='text-green-500'>[calls]</span>
		}
		if (calls < puts){
			return <span className='text-red-500'>[puts]</span>
		}
	};
	// const showSimilarTickers = async (tickerDetails) => {
	// 	if (!tickerDetails || !tickerDetails.SicCode) { return; }
	// 	let { SicCode } = tickerDetails;
	// 	let matchingTickers = await fetch(`${config.ursaapi}/tickersbysiccode?sic=${SicCode}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message === 'ok' && d.result ? d.result : null);
	// 	if (!matchingTickers) { return; }
	// 	matchingTickers = matchingTickers && Object.keys(matchingTickers).length ? Object.values(matchingTickers).sort((a, b) => b.MarketCap - a.MarketCap) : null;
	// 	if (!matchingTickers) { return; }
	// 	updateTickersBySicData(matchingTickers);
	// 	updateShowTickersBySic(true);
	// };
  const modalTradeDetail = (trade) => {
    if (!trade){ return; }
    trade = {
			ticker: tickerDetails.Ticker,
			name: tickerDetails.Name,
      time: trade.EntryTimestamp,
      qyt: trade.Size,
      price: trade.Price,
      value: trade.Notional,
      ask: trade.ask,
      mid: trade.mid,
      bid: trade.bid,
      ex: trade.Exchange,
      side: trade.side,
      flags: trade.Conditions,
    };
    let _tradeDetail = compileTradeDetailForModal(trade);
    updateTradeDetail(_tradeDetail);
    updateShowTradeInfo(true);
  };
	const displayTradeColumns = (trade, previousClose) => {
		return (
			<div className="container mx-auto cursor-help" onClick={() => modalTradeDetail(trade)} >
				<div className="flex justify-between">
					<span className="w-1/3 flex flex-row gap-2 justify-end items-center text-xxs text-gray-400">
						{ displayTradeCompressedReturnJSX(lastTrade, true, 'Size') }
						<span>@</span>
					</span>
					<span className="w-1/3 flex flex-col justify-center items-center">
						{ displayTradeCompressedReturnJSX(lastTrade, true, 'Price') }
					</span>
					<span className="w-1/3 flex flex-col justify-center items-center">
						{ previousClose && trade.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(trade.Price, previousClose), {noBrackets:true}) : '-' }
					</span>
				</div>
			</div>
		);
	};
	const displayVwapUi = (tickerDetails) => {
		if (!tickerDetails || !tickerDetails.StockNotional || !tickerDetails.Volume) { return; }
		let last = tickerDetails && tickerDetails.Change ? tickerDetails.Change : null;
		let vwap = truncateToTwoDigits((tickerDetails.StockNotional / tickerDetails.Volume), 4);
		return (<span className={last && vwap < last ? 'text-red-300' : (last && vwap > last ? 'text-green-300' : 'text-gray-500')}>${vwap}</span>);
	};
	const barUI = (item) => {
		let sharesRepresentedByCallsPercent = Math.abs(Math.trunc((Number(item.SharesRepresentedByCalls) / Number(item.SharesRepresentedTotal)) * 100));
		let sharesRepresentedByPutsPercent = Math.abs(Math.trunc((Number(item.SharesRepresentedByPuts) / Number(item.SharesRepresentedTotal)) * 100));
		
		return <span className='flex flex-row justify-between w-full text-xxs h-1'>
			<span style={{ width: `${sharesRepresentedByCallsPercent}%` }} className={`bg-emerald-700 flex items-center justify-start`}></span>
			<span style={{ width: `${sharesRepresentedByPutsPercent}%` }} className={`bg-red-700 flex items-center justify-end`}></span>
		</span>;
	};
	const barUIOIValue = (item) => {
		let total = Math.trunc(item.CallValue + item.PutValue);
		let calls = Math.abs(Math.trunc((Number(item.CallValue) / Number(total)) * 100));
		let puts = Math.abs(Math.trunc((Number(item.PutValue) / Number(total)) * 100));
		
		return <span className='flex flex-row justify-between w-full text-xxs h-1'>
			<span style={{ width: `${calls}%` }} className={`bg-emerald-700 flex items-center justify-start`}></span>
			<span style={{ width: `${puts}%` }} className={`bg-red-700 flex items-center justify-end`}></span>
		</span>;
	};
	const barUiVolumePriceBased = (item) => {
		let total = Math.trunc(item.Volume);
		let buys = Math.abs(Math.trunc((Number(item.VolumePriceBasedAscent) / Number(total)) * 100));
		let sells = Math.abs(Math.trunc((Number(item.VolumePriceBasedDescent) / Number(total)) * 100));
		let mid = Math.abs(100 - buys - sells);
		
		return <span className='flex flex-row justify-between w-full mb-1 gap-1'>
			<span className='flex flex-row justify-between flex-grow text-xxs h-2 p-0' onClick={() => showVolumePriceBasedInfo ? updateShowVolumePriceBasedInfo(false) : updateShowVolumePriceBasedInfo(true)}>
				<span style={{ width: `${buys}%` }} className={`bg-emerald-700 flex items-center justify-start text-[8px] text-white`}>{buys && buys>=5 ? `${buys}%` : ``}</span>
				<span style={{ width: `${mid}%` }} className={`bg-sky-700 flex items-center justify-center text-[8px] text-white`}>{mid && mid>=5 ? `${mid}%` : ``}</span>
				<span style={{ width: `${sells}%` }} className={`bg-red-700 flex items-center justify-end text-[8px] text-white`}>{sells && sells>=5 ? `${sells}%` : ``}</span>
			</span>
		</span>;
	};
	const barUiPremiumPriceBased = (item) => {
		let total = Math.trunc(item.PremiumPriceBasedBullish + item.PremiumPriceBasedBearish);
		let buys = Math.abs(Math.trunc((Number(item.PremiumPriceBasedBullish) / Number(total)) * 100));
		let sells = Math.abs(Math.trunc((Number(item.PremiumPriceBasedBearish) / Number(total)) * 100));
		
		return <span className='flex flex-row justify-between w-full mb-1 gap-1'>
			<span className='flex flex-row justify-between flex-grow text-xxs h-6 p-0'>
				<span style={{ width: `${buys}%` }} className={`bg-emerald-700 flex flex-col items-start justify-center text-[8px] text-white`}>{buys && buys>=5 ? <span>{buys}%</span> : ``}{buys && buys>=5 ? <span>${abbrInt(item.PremiumPriceBasedBullish, false)}</span> : ``}</span>
				<span style={{ width: `${sells}%` }} className={`bg-red-700 flex flex-col items-end justify-center text-[8px] text-white`}>{sells && sells>=5 ? <span>{sells}%</span> : ``}{sells && sells>=5 ? <span>${abbrInt(item.PremiumPriceBasedBearish, false)}</span> : ``}</span>
			</span>
		</span>;
	};
	const barUIMoneyness = (item, moneyness="ITM") => {
		let total = Math.trunc(item[`${moneyness}CALLShares`] + item[`${moneyness}PUTShares`]);
		let calls = Math.abs(Math.trunc((Number(item[`${moneyness}CALLShares`]) / Number(total)) * 100));
		let puts = Math.abs(Math.trunc((Number(item[`${moneyness}PUTShares`]) / Number(total)) * 100));
		
		return <span className='flex flex-row justify-between w-full text-xxs h-1'>
			<span style={{ width: `${calls}%` }} className={`bg-emerald-700 flex items-center justify-start`}></span>
			<span style={{ width: `${puts}%` }} className={`bg-red-700 flex items-center justify-end`}></span>
		</span>;
	};
	const outputHighestOptionSide = (stats) => {
		let highestSide = 'hedge';
		let binaryCallPercent = stats.OptionBinaryCallPremium ? Math.abs(Math.trunc((Number(stats.OptionBinaryCallPremium) / Number(stats.OptionNotional)) * 100)) : 0;
		let binaryPutPercent = stats.OptionBinaryPutPremium ? Math.abs(Math.trunc((Number(stats.OptionBinaryPutPremium) / Number(stats.OptionNotional)) * 100)) : 0;
		let nonBinaryPercent = 100 - binaryCallPercent - binaryPutPercent;
		let nonBinaryTotal = Number(stats.OptionNotional) - (Number(stats.OptionBinaryCallPremium) + Number(stats.OptionBinaryPutPremium));
		if (Number(nonBinaryTotal) > Number(stats.OptionBinaryCallPremium) && Number(nonBinaryTotal) > Number(stats.OptionBinaryPutPremium)) {
			highestSide = 'hedge';
		}else if (Number(stats.OptionBinaryCallPremium) > Number(nonBinaryTotal) && Number(stats.OptionBinaryCallPremium) > Number(stats.OptionBinaryPutPremium)) {
			highestSide = 'calls';
		}else if (Number(stats.OptionBinaryPutPremium) > Number(nonBinaryTotal) && Number(stats.OptionBinaryPutPremium) > Number(stats.OptionBinaryCallPremium)) {
			highestSide = 'puts';
		}

		return <span className='grid grid-cols-6 w-full text-xxs my-1'>
			<div className="col-span-6 h-full w-full text-white p-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
				<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-green-300'><span>Binary Calls</span><span className='text-green-300'>${abbrInt(stats.OptionBinaryCallPremium, false)}</span></span>
				<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-sky-300'><span>Hedging</span><span className='text-sky-300'>${abbrInt(nonBinaryTotal, false)}</span></span>
				<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-red-300'><span>Binary Puts</span><span className='text-red-300'>${abbrInt(stats.OptionBinaryPutPremium, false)}</span></span>
			</div>
			<span className='col-span-6 inline-flex justify-between w-full items-center'>
				<span className='flex flex-row justify-between w-full text-xxs sm:mx-1 rounded-lg border'>
					<span style={{ width: `${binaryCallPercent}%` }} className={`bg-emerald-700 flex items-center justify-start rounded-l-lg text-white pl-2 text-[8px]`}>{binaryCallPercent}%</span>
					<span style={{ width: `${nonBinaryPercent}%` }} className={`bg-sky-700 flex items-center justify-center text-white text-[8px]`}>{nonBinaryPercent}%</span>
					<span style={{ width: `${binaryPutPercent}%` }} className={`bg-red-700 flex items-center justify-end rounded-r-lg text-white pr-2 text-[8px]`}>{binaryPutPercent}%</span>
				</span>
			</span>
		</span>;
	};

	let MarketCapChange = 0;
	if (tickerDetails && tickerDetails.PreviousClose && tickerDetails.OutstandingShares && tickerDetails.Change) {
		let InitalMarketCap = tickerDetails.PreviousClose * tickerDetails.OutstandingShares;
		let CurrentMarketCap = tickerDetails.Change * tickerDetails.OutstandingShares;
		MarketCapChange = CurrentMarketCap - InitalMarketCap;
	}

  return (
    <div className="shadow overflow-hidden rounded-md w-full md:w-96">
      
      <div className="pt-2">
        <dl className="divide-y divide-gray-700">
          { tickerDetails && tickerDetails.hasOwnProperty('Name') ?
						<div className="py-0 px-0 mt-2 text-center text-sm flex justify-center items-center relative">
							<span className={`mr-2 inline-flex rounded-full h-2 w-2 absolute left-2 ${!delayedData && market==='open' ? 'bg-green-500 animate-ping' : ''}${!delayedData && market==='pre' ? 'bg-yellow-400 animate-ping' : ''}${!delayedData && market==='post' ? 'bg-amber-500 animate-ping' : ''}${!market || market==='closed' || delayedData  ? 'bg-red-500' : ''}`}></span>
							{ tickerDetails && tickerDetails.hasOwnProperty('Website') ? <a href={ tickerDetails.Website } target="_blank">{ formatStockName(tickerDetails.Name) }</a> : formatStockName(tickerDetails.Name) }
						</div>
						: '-'
          }
          <div className="py-0 grid grid-cols-3 gap-4 px-0">
						{/* <dt className="text-sm font-medium text-gray-500"> */}
						<dt className="flex flex-col items-start justify-start">
							<span className="text-sm font-medium text-gray-500">Last</span>
							{tickerDetails && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.Market && tickerDetails.TradeLastExtended.Market !== 'POST' && tickerDetails.TradeLast && tickerDetails.TradeLast.EntryTimeString ? <span className='w-full  bg-slate-800 text-sky-300 rounded px-1 text-[7px] text-center'>{tickerDetails.TradeLast.EntryTimeString}</span> : <></>}
							{tickerDetails && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.Market && tickerDetails.TradeLastExtended.Market === 'POST' && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.EntryTimeString ? <span className='w-full  bg-slate-800 text-amber-500 rounded px-1 text-[7px] text-center'>{tickerDetails.TradeLastExtended.EntryTimeString}</span> : <></>}
							{/* {tickerDetails && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.EntryTimeString ? <span className='mx-1 bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeLastExtended.EntryTimeString}</span> : <></>} */}
						</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              { lastTrade && lastTrade.Price && lastTrade.Size && tickerDetails && tickerDetails.PreviousClose ? displayTradeColumns(lastTrade, tickerDetails.PreviousClose) : <></> }
							{ (lastTrade===null || !lastTrade.Price || !lastTrade.Size) && tickerDetails && tickerDetails.TradeLast ? displayTradeColumns(tickerDetails.TradeLast, tickerDetails.PreviousClose) : <></> }
							
              { tickerDetails && tickerDetails.TradeLastExtended && (!tickerDetails.TradeOpen || tickerDetails.TradeClose) ?
              <>
								<span className='text-xxs inline-flex items-center justify-center w-full cursor-help text-gray-500' onClick={() => modalTradeDetail(tickerDetails.TradeLastExtended) }>
									{/* <span className="flex flex-grow"> */}
										{displayTradeCompressedReturnJSX(tickerDetails.TradeLastExtended)}
									{/* </span> */}
									{/* <span className="flex flex-col items-center justify-center"> */}
										{/* <span className="flex flex-row items-between justify-center"> */}
											{tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeLastExtended.Price, tickerDetails.PreviousClose)) : '-'}
											{tickerDetails && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.Market ? <span className={`mx-1 bg-gray-800 ${tickerDetails.TradeLastExtended.Market === 'PRE' ? 'text-yellow-400' : (tickerDetails.TradeLastExtended.Market === 'POST' ? 'text-amber-500' : 'text-green-500')} rounded px-1 text-[7px]`}>{(tickerDetails.TradeLastExtended.Market).toLowerCase()}</span> : <></>}
										{/* </span> */}
										{/* {tickerDetails && tickerDetails.TradeLastExtended && tickerDetails.TradeLastExtended.EntryTimeString ? <span className='mx-1 bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeLastExtended.EntryTimeString}</span> : <></>} */}
									{/* </span> */}
								</span>
							</>
							: <></> }

            </dd>
					</div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${delayedData ? 'hidden' : ''}`}>
						<dt className="text-sm font-medium text-gray-500">Quote</dt>
						<dd className="text-sm text-white mt-0 col-span-2 inline-flex gap-1">
							<div className="h-full w-full grid grid-cols-[1fr_40px_1fr] gap-2 text-white">
								{unauthorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
									<span className="text-xxs sm:text-xs flex flex-col items-center justify-center bg-rose-950/40 rounded px-3 py-0.5">
										<span>Bid</span>
										<span className='text-rose-400'>{tickerDetails && tickerDetails.Bid ? `$${tickerDetails.Bid}` : '-'}</span>
									</span>
									:
									<button
										onClick={() => { updateOrderTradeData({ Ticker: tickerDetails.Ticker, Price: tickerDetails.Bid }); updateShowShareTradeModal(true) }}
										className="text-xxs sm:text-xs border border-rose-800 flex flex-col items-center justify-center bg-rose-950/40 rounded px-3 py-0.5">
										<span>Bid</span>
										<span className='text-rose-400'>{tickerDetails && tickerDetails.Bid ? `$${(tickerDetails.Bid).toLocaleString()}` : '-'}</span>
									</button>
								}
								{tickerDetails && tickerDetails.Ask && tickerDetails.Bid && truncateToTwoDigits(((tickerDetails.Ask / tickerDetails.Bid) - 1) * 100) > 0 ? 
									<span className='flex flex-col items-center justify-center bg-sky-950/40 rounded px-3 py-0.5 text-[8px] leading-3'>
										<span className='text-amber-500'>Spread</span>
										<span className='text-sky-400'>{Number(truncateToTwoDigits(((tickerDetails.Ask / tickerDetails.Bid) - 1) * 100))}%</span>
									</span> 
									: <div className="w-10"></div>
								}
								{unauthorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
									<span className="text-xxs sm:text-xs flex flex-col items-center justify-center bg-emerald-950/40 rounded px-3 py-0.5">
										<span>Ask</span>
										<span className='text-emerald-400'>{tickerDetails && tickerDetails.Ask ? `$${tickerDetails.Ask}` : '-'}</span>
									</span>
									:
									<button
										onClick={() => { updateOrderTradeData({ Ticker: tickerDetails.Ticker, Price: tickerDetails.Ask }); updateShowShareTradeModal(true) }}
										className="text-xxs sm:text-xs border border-emerald-800 flex flex-col items-center justify-center bg-emerald-950/40 rounded px-3 py-0.5">
										<span>Ask</span>
										<span className='text-emerald-400'>{tickerDetails && tickerDetails.Ask ? `$${(tickerDetails.Ask).toLocaleString()}` : '-'}</span>
									</button>
								}
							</div>

						</dd>
          </div>
          <div className="py-0 grid grid-cols-3 gap-4 px-0">
            <dt className="text-sm font-medium text-gray-500">Volume</dt>
						<dd className="text-sm text-white mt-0 col-span-2 flex flex-col items-center justify-center">
							<span className="flex flex-row items-center justify-center w-full">
								{ tickerDetails && tickerDetails.hasOwnProperty('Volume') ?
									<>
										{/* <span className="hidden xl:block">{ (tickerDetails.Volume).toLocaleString() }</span> */}
										<span className="flex flex-grow items-center px-1 my-1 rounded-md border-none bg-gradient-to-r from-gray-700 to-blue-900 text-white ">
											<button
												type="button"
												className="m-0 w-full flex-shrink-0 flex items-center justify-center text-white text-xs"
												onClick={() => updateShowVolumeInfo(!showVolumeInfo) }>
												{ (tickerDetails.Volume).toLocaleString() }
											</button>
										</span>
									</>
									: '-'
								}
								{ tickerDetails && tickerDetails.OverHighestVolume ? <span className={`mx-1 bg-gray-800 text-amber-500 rounded px-1 text-[7px]`}>highest in 2 yrs</span> : (tickerDetails && tickerDetails.OverAverageVolume ? <span className={`mx-1 bg-gray-800 text-amber-500 rounded px-1 text-[7px]`}>over average</span> : <></>)}
								{/* { tickerDetails && tickerDetails.hasOwnProperty('TotalVolume') ?
									<>
										<span className="hidden xl:block">{ (tickerDetails.TotalVolume).toLocaleString() }</span>
										<span className="flex items-center p-1 my-1 rounded-md border-none bg-gradient-to-r from-blue-700 to-blue-900 text-white xl:hidden">
											<button
												type="button"
												className="m-0 w-full flex-shrink-0 flex items-center justify-center text-white text-xs"
												onClick={() => updateShowVolumeInfo(!showVolumeInfo) }>
												{ (tickerDetails.TotalVolume).toLocaleString() }
											</button>
										</span>
									</>
									: '-'
								} */}
							</span>
							{tickerDetails.VolumePriceBasedAscent && tickerDetails.VolumePriceBasedDescent ? barUiVolumePriceBased(tickerDetails) : <></>}
            </dd>
          </div>
					{/* <div className={`py-0 grid grid-cols-3 gap-4 px-0 ${tickerDetails && tickerDetails.OutstandingShares ? 'block' : 'hidden'}`}> */}
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Outstanding</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails && tickerDetails.hasOwnProperty('OutstandingShares') && tickerDetails.OutstandingShares && isNaN(tickerDetails.OutstandingShares)===false ?
                <>{ (tickerDetails.OutstandingShares).toLocaleString() }</>
                : '-'
              }
            </dd>
          </div>
					<div className={`py-0.5 grid grid-cols-3 gap-4 px-0 ${showTickerInfo || (tickerDetails && tickerDetails.hasOwnProperty('Turnover') && !isNaN(tickerDetails.Turnover) && tickerDetails.Turnover>25) || (tickerDetails && tickerDetails.hasOwnProperty('OptionTurnover') && !isNaN(tickerDetails.OptionTurnover) && tickerDetails.OptionTurnover>25) ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Turnover</dt>
            <dd className="text-sm text-white mt-0 col-span-2 flex flex-row items-center justify-evenly">
							{ tickerDetails && tickerDetails.hasOwnProperty('Turnover') && tickerDetails.Turnover && !isNaN(tickerDetails.Turnover) ?
								<span className={`w-20 rounded px-1 py-0.5 text-xs text-sky-300 bg-sky-950 flex flex-row gap-0.5 items-center justify-center`}>
									<span>{(tickerDetails.Turnover).toLocaleString()}%</span>
									<span className="text-xxs text-white">shares</span>
								</span>
                : ''
							}
							{ tickerDetails && tickerDetails.hasOwnProperty('OptionTurnover') && tickerDetails.OptionTurnover && !isNaN(tickerDetails.OptionTurnover) ?
								<span className={`w-20 rounded px-1 py-0.5 text-xs text-sky-300 bg-sky-950 flex flex-row gap-0.5 items-center justify-center`}>
									<span>{(tickerDetails.OptionTurnover).toLocaleString()}%</span>
									<span className="text-xxs text-white">options</span>
								</span>
                : ''
              }
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Total Trades</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails && tickerDetails.hasOwnProperty('Trades') && tickerDetails.Trades && !isNaN(tickerDetails.Trades) ?
                <>{ (tickerDetails.Trades).toLocaleString() }</>
                : '-'
              }
            </dd>
          </div>
          <div className="py-0 grid grid-cols-3 gap-4 px-0">
            <dt className="text-sm font-medium text-gray-500">Previous Close</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails && tickerDetails.hasOwnProperty('PreviousClose') && tickerDetails.PreviousClose!==0 ?
                <>${ (tickerDetails.PreviousClose).toLocaleString() } <span className='text-xxs text-gray-500'>[{ tickerDetails.PreviousCloseDate }]</span></>
                : '-'
              }
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${tickerDetails && tickerDetails.hasOwnProperty('TradeOpen') && tickerDetails.TradeOpen ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Open</dt>
            {/* <dd className="text-sm text-white mt-0 col-span-2 cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeOpen[tickerDetails.Exchange]) }> */}
            <dd className="text-sm text-white mt-0 col-span-2 cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeOpen) }>
              {/* { tickerDetails && tickerDetails.hasOwnProperty('TradeOpen') && tickerDetails.hasOwnProperty('Exchange') && tickerDetails.TradeOpen!==null && tickerDetails.TradeOpen[tickerDetails.Exchange] ? displayTradeCompressedReturnJSX(tickerDetails.TradeOpen[tickerDetails.Exchange]) : '-' } */}
              { tickerDetails && tickerDetails.hasOwnProperty('TradeOpen') && tickerDetails.TradeOpen!==null ? displayTradeCompressedReturnJSX(tickerDetails.TradeOpen) : '-' }
							<span className="text-xxs">{ tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeOpen && tickerDetails.TradeOpen.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeOpen.Price, tickerDetails.PreviousClose)) : '-' }</span>
            </dd>
          </div>
          <div className="py-0 grid grid-cols-3 gap-4 px-0">
            <dt className="text-sm font-medium text-gray-500">High</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              <span className="cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeHigh) }>
                { tickerDetails && tickerDetails.hasOwnProperty('TradeHigh') ? displayTradeCompressedReturnJSX(tickerDetails.TradeHigh) : '-' }
								{tickerDetails && tickerDetails.PreviousClose && tickerDetails.High ? <span className="text-xxs">{displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.High, tickerDetails.PreviousClose))}</span> : ''}
								{tickerDetails && tickerDetails.TradeHigh && tickerDetails.TradeHigh.EntryTimeString ? <span className='bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeHigh.EntryTimeString}</span> : <></>}
              </span>
							<span className={`cursor-help ${(tickerDetails && (!tickerDetails.TradeOpen || tickerDetails.TradeClose)) ? 'block' : 'hidden'}`} onClick={() => modalTradeDetail(tickerDetails.TradeHighExtended) }>
								{tickerDetails && tickerDetails.hasOwnProperty('TradeHighExtended') &&
									<>
										<span className='text-gray-500'>{displayTradeCompressedReturnJSX(tickerDetails.TradeHighExtended)}
										<span className="text-xxs">{tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeHighExtended && tickerDetails.TradeHighExtended.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeHighExtended.Price, tickerDetails.PreviousClose)) : '-'}</span>
											{/* <span className='text-xxs'>[extended]</span> */}
											{tickerDetails && tickerDetails.TradeHighExtended && tickerDetails.TradeHighExtended.Market ? <span className={`bg-gray-800 ${tickerDetails.TradeHighExtended.Market === 'PRE' ? 'text-yellow-400' : (tickerDetails.TradeHighExtended.Market === 'POST' ? 'text-amber-500' : 'text-green-500')} rounded px-1 text-[7px]`}>{(tickerDetails.TradeHighExtended.Market).toLowerCase()}</span> : <></>}
											{tickerDetails && tickerDetails.TradeHighExtended && tickerDetails.TradeHighExtended.EntryTimeString ? <span className='bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeHighExtended.EntryTimeString}</span> : <></>}
										</span>
									</>
								}
              </span>
            </dd>
          </div>
          <div className="py-0 grid grid-cols-3 gap-4 px-0">
            <dt className="text-sm font-medium text-gray-500">Low</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              <span className="cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeLow) }>
                { tickerDetails && tickerDetails.hasOwnProperty('TradeLow') ? displayTradeCompressedReturnJSX(tickerDetails.TradeLow) : '-' }
								{tickerDetails && tickerDetails.PreviousClose && tickerDetails.Low ? <span className="text-xxs">{displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.Low, tickerDetails.PreviousClose))}</span> : ''}
								{tickerDetails && tickerDetails.TradeLow && tickerDetails.TradeLow.EntryTimeString ? <span className='bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeLow.EntryTimeString}</span> : <></>}
              </span>
              <span className={`cursor-help ${(tickerDetails && (!tickerDetails.TradeOpen || tickerDetails.TradeClose)) ? 'block' : 'hidden'}`} onClick={() => modalTradeDetail(tickerDetails.TradeLowExtended) }>
								{tickerDetails && tickerDetails.hasOwnProperty('TradeLowExtended') &&
									<>
										<span className='text-gray-500'>{displayTradeCompressedReturnJSX(tickerDetails.TradeLowExtended)} 
										<span className="text-xxs">{tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeLowExtended && tickerDetails.TradeLowExtended.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeLowExtended.Price, tickerDetails.PreviousClose)) : '-'}</span>
											{/* <span className='text-xxs'>[extended]</span> */}
											{tickerDetails && tickerDetails.TradeLowExtended && tickerDetails.TradeLowExtended.Market ? <span className={`bg-gray-800 ${tickerDetails.TradeLowExtended.Market === 'PRE' ? 'text-yellow-400' : (tickerDetails.TradeLowExtended.Market === 'POST' ? 'text-amber-500' : 'text-green-500')} rounded px-1 text-[7px]`}>{(tickerDetails.TradeLowExtended.Market).toLowerCase()}</span> : <></>}
											{tickerDetails && tickerDetails.TradeLowExtended && tickerDetails.TradeLowExtended.EntryTimeString ? <span className='bg-slate-800 text-sky-300 rounded px-1 text-[7px]'>{tickerDetails.TradeLowExtended.EntryTimeString}</span> : <></>}
										</span>
									</>
								}
              </span>
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${tickerDetails && tickerDetails.TradeClose ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Close</dt>
            <dd className="text-sm text-white mt-0 col-span-2 cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeClose) }>
              { tickerDetails && tickerDetails.TradeClose!==null ? displayTradeCompressedReturnJSX(tickerDetails.TradeClose) : '-' }
							<span className="text-xxs">{ tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeClose && tickerDetails.TradeClose.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeClose.Price, tickerDetails.PreviousClose)) : '-' }</span>
            </dd>
          </div>
					{/* <div className={`py-0 grid grid-cols-3 gap-4 px-0 ${tickerDetails && tickerDetails.Volume && tickerDetails.StockNotional ? 'block' : 'hidden'}`}> */}
					{/* <div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Average Price</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              {displayVwapUi(tickerDetails)} <span className='text-xxs text-sky-500'>[volume weighted]</span>
            </dd>
          </div> */}
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Highest Volume Trade</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							<span className="cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeHighVolume) }>
                { tickerDetails && tickerDetails.hasOwnProperty('TradeHighVolume') ? displayTradeCompressedReturnJSX(tickerDetails.TradeHighVolume) : '-' }
                { tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeHighVolume && tickerDetails.TradeHighVolume.Price ? <span className="text-xxs">{ displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeHighVolume.Price, tickerDetails.PreviousClose)) }</span> : '' }
              </span>
              <span className="cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeHighVolumeExtended) }>
                { tickerDetails && tickerDetails.hasOwnProperty('TradeHighVolumeExtended') && <><br/><span className='text-gray-500'>{ displayTradeCompressedReturnJSX(tickerDetails.TradeHighVolumeExtended)} 
                <span className="text-xxs">{ tickerDetails && tickerDetails.PreviousClose && tickerDetails.TradeHighVolumeExtended && tickerDetails.TradeHighVolumeExtended.Price ? displayColorSignedPercentJSX(calculateChangeInPercent(tickerDetails.TradeHighVolumeExtended.Price, tickerDetails.PreviousClose)) : '-' }</span> <span className='text-xxs'>[extended]</span></span></>}
              </span>
            </dd>
					</div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${(showTickerInfo || (tickerDetails && tickerDetails.OverAverageRange)) && (tickerDetails && tickerDetails.Range) ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Range</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails.Range }
							{ tickerDetails && tickerDetails.OverHighestRange ? <span className={`mx-1 bg-gray-800 text-amber-500 rounded px-1 text-xxxs`}>highest in 2 yrs</span> : (tickerDetails && tickerDetails.OverAverageRange ? <span className={`mx-1 bg-gray-800 text-amber-500 rounded px-1 text-xxxs`}>over average</span> : <></>)}
            </dd>
					</div>
					{/* <div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}> */}
					<div className={`py-0.5 grid grid-cols-3 gap-4 px-0`}>
            <dt className="text-sm font-medium text-sky-400">Capital Flow</dt>
            <dd className="text-sm text-white mt-0 col-span-2 flex flex-row items-center justify-evenly">
							{ tickerDetails && tickerDetails.StockNotional && authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
								<button className={`w-24 rounded px-1 py-0.5 text-xs text-cyan-400 border border-cyan-800 bg-cyan-950/40 flex flex-row gap-0.5 items-center justify-center`} onClick={() => { showStockNotionalChart(true); }}>
									<span>${abbrInt(tickerDetails.StockNotional, false)}</span>
									<span className="text-xxs text-white">shares</span>
								</button>
								: (
									tickerDetails && tickerDetails.StockNotional ?
										<span className={`w-24 rounded px-1 py-0.5 text-xs text-cyan-400 bg-cyan-950/40 flex flex-row gap-0.5 items-center justify-center`}>
											<span>${abbrInt(tickerDetails.StockNotional, false)}</span>
											<span className="text-xxs text-white">shares</span>
										</span>
										: ''
								)
              }
							{ tickerDetails && tickerDetails.OptionNotional && authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond') ?
								<button className={`w-24 rounded px-1 py-0.5 text-xs text-cyan-400 border border-cyan-800 bg-cyan-950/40 flex flex-row gap-0.5 items-center justify-center`} onClick={() => { showOptionNotionalChart(true); }}>
									<span>${abbrInt(tickerDetails.OptionNotional, false)}</span>
									<span className="text-xxs text-white">options</span>
								</button>
								: (
									tickerDetails && tickerDetails.OptionNotional ?
										<span className={`w-24 rounded px-1 py-0.5 text-xs text-cyan-400 bg-cyan-950/40 flex flex-row gap-0.5 items-center justify-center`}>
											<span>${abbrInt(tickerDetails.OptionNotional, false)}</span>
											<span className="text-xxs text-white">options</span>
										</span>
										: ''
								)
              }
            </dd>
          </div>
					{tickerDetails && tickerDetails.MarketCap ? <div className="py-1 grid grid-cols-3 gap-4 px-0">
						<dt className="text-sm font-medium text-gray-500">Market Cap</dt>
						<dd className="text-sm text-white mt-0 col-span-2 inline-flex gap-5">
							{/* <span className='text-ms'>{`$${abbrInt(tickerDetails.MarketCap, false)}`}</span> */}
							{/* <span className={`rounded px-1 py-0.5 text-xs ${tickerDetails && tickerDetails.MarketCapChange ? (tickerDetails.MarketCapChange > 0 ? 'text-green-300 bg-green-900' : (tickerDetails.MarketCapChange < 0 ? 'text-red-300 bg-red-900' : '')) : ''}`}>{tickerDetails && tickerDetails.MarketCapChange ? signedCurrency(abbrInt(tickerDetails.MarketCapChange, false), '$') : '-'}</span> */}
							<span className='text-ms'>{`$${abbrInt(tickerDetails.MarketCap, false)}`}</span>
							<span className={`rounded px-1 py-0.5 text-xs ${MarketCapChange ? (MarketCapChange > 0 ? 'text-green-300 bg-green-900' : (MarketCapChange < 0 ? 'text-red-300 bg-red-900' : '')) : ''}`}>{MarketCapChange ? signedCurrency(abbrInt(MarketCapChange, false), '$') : '-'}</span>
						</dd>
					</div> : <></>}
					{/* {tickerDetails && (tickerDetails.CostToBorrowMin || tickerDetails.CostToBorrowAverage || tickerDetails.CostToBorrowMax) ? <div className="py-1 grid grid-cols-3 gap-4 px-0">
						<dt className="text-sm font-medium text-gray-500">Cost To Borrow</dt>
						<dd className="text-sm text-white mt-0 col-span-2 inline-flex gap-1">
							<div className="h-full w-full text-white px-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'><span className='block sm:hidden'>Min.</span><span className='hidden sm:block'>Minimum</span><span className='text-amber-400'>{tickerDetails.CostToBorrowMin ? `${tickerDetails.CostToBorrowMin}%` : `-`}</span></span>
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'><span className='block sm:hidden'>Avg.</span><span className='hidden sm:block'>Average</span><span className='text-amber-400'>{tickerDetails.CostToBorrowAverage ? `${tickerDetails.CostToBorrowAverage}%` : `-`}</span></span>
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'><span className='block sm:hidden'>Max.</span><span className='hidden sm:block'>Maximum</span><span className='text-amber-400'>{tickerDetails.CostToBorrowMax ? `${tickerDetails.CostToBorrowMax}%` : `-`}</span></span>
							</div>
						</dd>
					</div> : <></>} */}
					{/* {tickerDetails && (tickerDetails.SharesOnLoanAtOpen || tickerDetails.SharesOnLoanNow || tickerDetails.SharesOnLoanChange || tickerDetails.SharesOnLoanNowPercentOfOutstanding || tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent) ? <div className="py-1 grid grid-cols-3 gap-4 px-0">
						<dt className="text-sm font-medium text-gray-500">Shares On Loan</dt>
						<dd className="text-sm text-white mt-0 col-span-2 inline-flex flex-col gap-1">
							<div className="h-full w-full text-white px-2 gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'><span>At Open</span><span className='text-amber-400'>{tickerDetails.SharesOnLoanAtOpen ? abbrInt(tickerDetails.SharesOnLoanAtOpen, false) : `-`}</span></span>
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'>
									<span>Last</span>
									<span className='text-amber-400'>{tickerDetails.SharesOnLoanNow ? abbrInt(tickerDetails.SharesOnLoanNow, false) : `-`} <span className={`rounded px-1 py-0.5 text-xs ${tickerDetails && tickerDetails.SharesOnLoanChange ? (tickerDetails.SharesOnLoanChange < 0 ? 'text-green-300 bg-green-900' : (tickerDetails.SharesOnLoanChange > 0 ? 'text-red-300 bg-red-950' : '')) : ''}`}>{tickerDetails && tickerDetails.SharesOnLoanChange ? signedCurrency(abbrInt(tickerDetails.SharesOnLoanChange, false), '') : '-'}</span></span>
								</span>
							</div>
							<div className="h-full w-full text-white px-2 gap-2 flex flex-row items-center justify-center md:justify-center flex-wrap">
								<span className='text-xxs sm:text-xs flex flex-col items-center justify-center'>
									<span>Percent of Outstanding</span>
									<span className='text-amber-400'>{tickerDetails.SharesOnLoanNowPercentOfOutstanding ? `${tickerDetails.SharesOnLoanNowPercentOfOutstanding}%` : `-`} <span className={`rounded px-1 py-0.5 text-xs ${tickerDetails && tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent ? (tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent < 0 ? 'text-green-300 bg-green-900' : (tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent > 0 ? 'text-red-300 bg-red-950' : '')) : ''}`}>{tickerDetails && tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent ? `${signedCurrency(abbrInt(tickerDetails.SharesOnLoanNowPercentOfOutstandingChangePercent, false), '')}%` : '-'}</span></span>
								</span>
							</div>
						</dd>
					</div> : <></>} */}
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            {/* <dt className="text-sm font-medium text-gray-400">SSR Price</dt> */}
            <dt className="text-sm font-medium text-gray-400">SSR</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              {/* { tickerDetails && tickerDetails.SsrThreshold ? Number((tickerDetails.SsrThreshold).toFixed(2)).toLocaleString() : '-' }  */}
              { tickerDetails && tickerDetails.PreviousClose ? Number((tickerDetails.PreviousClose * .9).toFixed(2)).toLocaleString() : '-' } 
              { tickerDetails && tickerDetails.SsrTriggered && <span className='ml-1 text-xxs text-red-700'>[triggered]</span> }
              { tickerDetails && tickerDetails.SsrFlagged && <span className='ml-1 text-xxs text-red-700'>[flagged]</span> }
            </dd>
          </div>
          { tickerDetails && tickerDetails.TradeSsrTriggered &&
						<div className={`py-0 grid grid-cols-3 gap-4 px-0 bg-red-900 ${showTickerInfo ? 'block' : 'hidden'}`}>
              <dt className="text-sm font-medium text-gray-400">SSR Trade</dt>
              <dd className="text-sm text-white mt-0 col-span-2 cursor-help" onClick={() => modalTradeDetail(tickerDetails.TradeSsrTriggered) }>
                { displayTradeCompressedReturnJSX(tickerDetails.TradeSsrTriggered) }
              </dd>
            </div>
          }
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Option Contracts</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails && tickerDetails.OptionContracts ?
								<>
									{/* {abbrInt(tickerDetails.OptionContracts, false, false)} */}
									{tickerDetails && tickerDetails.OptionContracts && isNaN(tickerDetails.OptionContracts)===false ? (tickerDetails.OptionContracts).toLocaleString() : '-'}
									{/* <span className='text-xxs text-gray-500'>[{tickerDetails.OptionsCheckedDateString}]</span> */}
								</>
                : '-'
              }
            </dd>
          </div>
					{tickerDetails && tickerDetails.OptionTrades && tickerDetails.OptionNotional && tickerDetails.OptionVolume ?
						<div className={`relative w-full  ${delayedData || market === 'pre' || market === 'closed' ? `bg-slate-800 pb-1 px-0.5` : `py-0 px-0`}`}>
							{showTickerInfo && decodedToken && decodedToken.level && authorizedLevel(decodedToken.level, 'diamond') && tickerDetails && tickerDetails.hasOwnProperty('PremiumPriceBasedBearish') && tickerDetails.hasOwnProperty('PremiumPriceBasedBullish') ? barUiPremiumPriceBased(tickerDetails) : <></>}
							{market === 'pre' ? <span className="text-white py-1 px-0.5 text-xxs items-center justify-center w-full flex bg-sky-900">Option Data from {delayedData ? delayedData : tickerDetails.PreviousCloseDate}</span> : (delayedData || market === 'closed' ? <span className="text-white py-1 px-0.5 text-xxs items-center justify-center w-full flex bg-sky-900">Option Data from {delayedData ? delayedData : tickerDetails.IndexDate}</span> : ``)}
							{/* {delayedData || market === 'closed' ? <span className="text-white py-1 px-0.5 text-xxs items-center justify-center w-full flex bg-sky-900">Option Data from {delayedData ? delayedData : tickerDetails.IndexDate}</span> : ``} */}
							{delayedData || market === 'pre' || market=== 'closed' ? <div className="absolute inset-0 bg-gray-800 opacity-30 z-10 border-2 border-sky-500"></div> : ''}
							<div className={`h-full w-full text-white p-2 gap-2 flex flex-row items-start justify-between md:justify-center flex-wrap`}>
								<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center'>
									<span>Option Trades</span>
									<span className='text-amber-400'>{abbrInt(tickerDetails.OptionTrades, false)}</span>
									{tickerStats && tickerStats.OptionTrades && tickerDetails.OptionTrades > tickerStats.OptionTrades.highest ? <span className="text-xxs text-yellow-900 font-bold bg-yellow-200 px-2 rounded text-center leading-3">highest in 2yrs</span> : ''}
								</span>
								<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center'>
									<span>Option Volume</span>
									<span className='text-amber-400'>{abbrInt(tickerDetails.OptionVolume, false)}</span>
									{tickerStats && tickerStats.OptionVolume && tickerDetails.OptionVolume > tickerStats.OptionVolume.highest ? <span className="text-xxs text-yellow-900 font-bold bg-yellow-200 px-2 rounded text-center leading-3">highest in 2yrs</span> : ''}
								</span>
								<span className='text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center'>
									<span>Option Premium</span>
									<span className='text-amber-400'>${abbrInt(tickerDetails.OptionNotional, false)}</span>
									{ tickerStats && tickerStats.OptionPremium && tickerDetails.OptionNotional > tickerStats.OptionPremium.highest ? <button className="text-xxs text-yellow-900 font-bold bg-yellow-200 px-2 rounded text-center leading-3" onClick={()=>{window.alert(`Previous High: $${abbrInt(tickerStats.OptionPremium.highest, false)}`)}}>highest in 2yrs</button> : ''}
								</span>
							</div>
							{outputHighestOptionSide(tickerDetails)}
						</div>
					: <></>}

					
				{/* {tickerDetails && tickerDetails.Moneyness ? <div className={`py-0 w-full px-0 flex flex-col sm:flex-row items-center justify-center gap-1`}>
					<div className={`py-0 w-full px-0 block`}>
						<div className={`flex flex-col gap-1 ${tickerDetails.Moneyness.ITMCALLShares && tickerDetails.Moneyness.ITMPUTShares && tickerDetails.Moneyness.ITMCALLShares > tickerDetails.Moneyness.ITMPUTShares ? 'bg-emerald-950' : (tickerDetails.Moneyness.ITMCALLShares && tickerDetails.Moneyness.ITMPUTShares && tickerDetails.Moneyness.ITMCALLShares < tickerDetails.Moneyness.ITMPUTShares ? 'bg-rose-950' : 'bg-gray-700')}`}>			
							<span className='w-full text-[8px] flex flex-row items-start justify-center whitespace-nowrap text-gray-400 truncate w-full text-slate-400'>
								In-The-Money Option Trades
							</span>
							<span className='grid grid-cols-6 w-full text-xxs'>
								<span className='col-span-3 flex flex-col items-start justify-start whitespace-nowrap text-gray-400 truncate w-full text-green-300'>
									<span className="w-full text-center">Call Shares</span>
									<span className="flex gap-1 w-full flex-row items-center justify-between">
										<span>{tickerDetails.Moneyness && tickerDetails.Moneyness.ITMCALLShares && isNaN(tickerDetails.Moneyness.ITMCALLShares) === false ? `${abbrInt(tickerDetails.Moneyness.ITMCALLShares, false)}` : '-'}</span>
										<span>{tickerDetails.Moneyness && tickerDetails.Moneyness.ITMCALLPremium && isNaN(tickerDetails.Moneyness.ITMCALLPremium) === false ? <span className="text-xxxs mx-0.5 rounded bg-gray-200 text-sky-900 px-0.5">${abbrInt(tickerDetails.Moneyness.ITMCALLPremium, false)}</span> : '-'}</span>
									</span>
								</span>
								<span className='col-span-3 flex flex-col items-start justify-start whitespace-nowrap text-gray-400 truncate w-full text-red-300'>
									<span className="w-full text-center">Put Shares</span>
									<span className="flex gap-1 w-full flex-row items-center justify-between">
										<span>{tickerDetails.Moneyness && tickerDetails.Moneyness.ITMPUTShares && isNaN(tickerDetails.Moneyness.ITMPUTShares) === false ? `${abbrInt(tickerDetails.Moneyness.ITMPUTShares, false)}` : '-'}</span>
										<span>{tickerDetails.Moneyness && tickerDetails.Moneyness.ITMPUTPremium && isNaN(tickerDetails.Moneyness.ITMPUTPremium) === false ? <span className="text-xxxs mx-0.5 rounded bg-gray-200 text-sky-900 px-0.5">${abbrInt(tickerDetails.Moneyness.ITMPUTPremium, false)}</span> : '-'}</span>
									</span>
								</span>
							</span>		
							{ tickerDetails.Moneyness ? barUIMoneyness(tickerDetails.Moneyness) : ''}
						</div>
					</div>
					<div className={`py-0 w-full px-0 block`}>
						<div className={`grid grid-rows-2 ${tickerDetails.Moneyness.OTMCALLShares && tickerDetails.Moneyness.OTMPUTShares && tickerDetails.Moneyness.OTMCALLShares > tickerDetails.Moneyness.OTMPUTShares ? 'bg-emerald-950' : (tickerDetails.Moneyness.OTMCALLShares && tickerDetails.Moneyness.OTMPUTShares && tickerDetails.Moneyness.OTMCALLShares < tickerDetails.Moneyness.OTMPUTShares ? 'bg-rose-950' : 'bg-gray-700')}`}>			
							<span className='grid grid-cols-6 w-full text-xxs gap-1'>
								<span className='text-[8px] col-span-6 flex flex-row gap-2 items-start justify-center whitespace-nowrap text-gray-400 truncate w-full'>
									<span className={`text-slate-400`}>Out-The-Money Option Trades</span>
								</span>
							</span>
							<span className='grid grid-cols-6 w-full text-xxs'>
								<span className='col-span-3 flex flex-row items-start justify-start whitespace-nowrap text-gray-400 truncate w-full text-green-300'>
									Call Shares: {tickerDetails.Moneyness && tickerDetails.Moneyness.OTMCALLShares && isNaN(tickerDetails.Moneyness.OTMCALLShares) === false ? `${abbrInt(tickerDetails.Moneyness.OTMCALLShares, false)}` : '-'} {tickerDetails.Moneyness && tickerDetails.Moneyness.OTMCALLPremium && isNaN(tickerDetails.Moneyness.OTMCALLPremium) === false ? <span className="text-xxxs mx-0.5 rounded bg-gray-200 text-sky-900 px-0.5">${abbrInt(tickerDetails.Moneyness.OTMCALLPremium, false)}</span> : '-'}
								</span>
								<span className='col-span-3 flex flex-row items-start justify-end whitespace-nowrap text-gray-400 truncate w-full text-red-300'>
									Put Shares: {tickerDetails.Moneyness && tickerDetails.Moneyness.OTMPUTShares && isNaN(tickerDetails.Moneyness.OTMPUTShares) === false ? `${abbrInt(tickerDetails.Moneyness.OTMPUTShares, false)}` : '-'} {tickerDetails.Moneyness && tickerDetails.Moneyness.OTMPUTPremium && isNaN(tickerDetails.Moneyness.OTMPUTPremium) === false ? <span className="text-xxxs mx-0.5 rounded bg-gray-200 text-sky-900 px-0.5">${abbrInt(tickerDetails.Moneyness.OTMPUTPremium, false)}</span> : '-'}
								</span>
							</span>		
							{ tickerDetails.Moneyness ? barUIMoneyness(tickerDetails.Moneyness, 'OTM') : ''}
						</div>
					</div>
				</div> : <></>} */}
					
					{optionOpenInterestValue ? <div className={`py-0 w-full px-0 ${showTickerInfo && optionOpenInterestValue ? 'block' : 'hidden'}`}>
						<div className={`grid grid-rows-2 ${optionOpenInterestValue.HigherByValue === "CALL" ? 'bg-emerald-950' : (optionOpenInterestValue.HigherByValue === "PUT" ? 'bg-rose-950' : 'bg-gray-700')}`}>			
							<span className='grid grid-cols-6 w-full text-xxs gap-1'>
								<span className='text-[8px] col-span-3 flex flex-row gap-2 items-start justify-start whitespace-nowrap text-gray-400 truncate w-full'>
									<span className={`text-slate-400`}>Change:</span>
									{optionOpenInterestValue.CallChangeValue ? <span className={`text-green-300`}>{ signedCurrency(abbrInt(optionOpenInterestValue.CallChangeValue, false), '$') }</span> : <></>}
									{optionOpenInterestValue.PutChangeValue ? <span className={`text-red-300`}>{ signedCurrency(abbrInt(optionOpenInterestValue.PutChangeValue, false), '$') }</span> : <></>}
								</span>
								<span className={`text-[8px] col-span-3 flex flex-row gap-1 items-start italic justify-end mx-1`}>
									{optionOpenInterestValue.AbsoluteChangeValue ? <span className={`text-sky-300`}>${abbrInt(optionOpenInterestValue.AbsoluteChangeValue, false)}</span> : <></>}
								</span>
							</span>
							<span className='grid grid-cols-6 w-full text-xxs'>
								<span className='col-span-3 flex flex-row items-start justify-start whitespace-nowrap text-gray-400 truncate w-full text-green-300'>Call Value: {optionOpenInterestValue.CallValue && isNaN(optionOpenInterestValue.CallValue) === false ? `$${abbrInt(optionOpenInterestValue.CallValue, false)}` : '-'}</span>
								<span className='col-span-3 flex flex-row items-start justify-end whitespace-nowrap text-gray-400 truncate w-full text-red-300'>Put Value: { optionOpenInterestValue.PutValue && isNaN(optionOpenInterestValue.PutValue)===false ? `$${abbrInt(optionOpenInterestValue.PutValue,false) }`: '-'}</span>
							</span>		
							{ barUIOIValue(optionOpenInterestValue) }
						</div>
					</div> : <></>}

					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Employees</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              { (!tickerDetails || !tickerDetails.Employees) ? '-' : '' }
              { tickerDetails && tickerDetails.Employees && tickerDetails.Employees && !isNaN(tickerDetails.Employees) ? <>{ (tickerDetails.Employees).toLocaleString() }</> : <></> }
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">IPO Date</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              { tickerDetails && tickerDetails.IpoDate ? <>{ tickerDetails.IpoDate }</> : <></> }
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">Headquarters</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              { tickerDetails && tickerDetails.PrincipalOffice && tickerDetails.PrincipalOffice.City && tickerDetails.PrincipalOffice.State ?
							capitalizeString(`${ tickerDetails.PrincipalOffice.City }, ${ tickerDetails.PrincipalOffice.State }`)
							: '-' }
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">SIC</dt>
            <dd className="text-xs text-white mt-0 col-span-2">
              { (tickerDetails && tickerDetails.SicCode && tickerDetails.SicDescription) ?
								<span className='flex flex-row items-start gap-1'>
									{/* <button className='bg-orange-300 text-orange-900 px-1.5 rounded' onClick={()=>{showSimilarTickers(tickerDetails)}}>{tickerDetails.SicCode}</button> */}
									<span className='bg-orange-300 text-orange-900 px-1.5 rounded'>{tickerDetails.SicCode}</span>
									<span className='truncate w-full'>{tickerDetails.SicDescription}</span>
								</span>
							: '-' }
							
            </dd>
          </div>
					<div className={`py-0 grid grid-cols-3 gap-4 px-0 ${showTickerInfo ? 'block' : 'hidden'}`}>
            <dt className="text-sm font-medium text-gray-500">CIK</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
              { (tickerDetails && tickerDetails.CIK) ?
								<a href={`https://www.sec.gov/edgar/search/#/ciks=${ tickerDetails.CIK }`} target="_blank">{ tickerDetails.CIK }</a>
							: '' }
							
            </dd>
          </div>
					{/* <div className="py-0 grid grid-cols-3 gap-4 px-0 bg-indigo-900">
            <dt className="text-sm font-medium text-gray-500">Stock + Option Value</dt>
            <dd className="text-sm text-white mt-0 col-span-2">
							{ tickerDetails && tickerDetails.CombinedStockOptionNotional && tickerDetails.CombinedStockOptionNotional>=0 ?
                <>${ abbrInt(tickerDetails.CombinedStockOptionNotional, false) }</>
                : '-'
              }
            </dd>
          </div> */}
        </dl>
      </div>


      <MODAL
        open={ showTickersBySic }
        setOpen={() => updateShowTickersBySic(false)}
				headline={`${tickerDetails && tickerDetails.SicCode ? `[${tickerDetails.SicCode}]` : 'Same'} SIC Tickers`}
        html_information={ true }
				information={TICKERS_WITH_SAME_SIC_FOR_MODAL(tickersBySicData, (tickerDetails && tickerDetails.Ticker ? tickerDetails.Ticker : null), (tickerDetails && tickerDetails.OutstandingShares ? tickerDetails.OutstandingShares : null)) } />
      
			{/* <MODAL
        open={ showVolumeInfo }
        setOpen={() => updateShowVolumeInfo(false)}
        headline="Exchange Volume"
        html_information={ true }
        information={ TICKER_EXCHANGE_VOLUME_DATA_AS_STRING_FOR_MODAL(tickerDetails) } /> */}

      <VOLUME_MODAL
				open={showVolumeInfo}
				setOpen={() => { updateShowVolumeInfo(false); }}
				ticker={tickerDetails && tickerDetails.Ticker ? tickerDetails.Ticker : null}
				totalVolume={tickerDetails && tickerDetails.Volume ? tickerDetails.Volume : 0}
				exchangeVolume={tickerDetails && tickerDetails.ExchangeVolume ? tickerDetails.ExchangeVolume : {}} />
      
			<MODAL
        open={ showTradeInfo }
        setOpen={() => { updateShowTradeInfo(false); updateTradeDetail(null); }}
        headline="Trade Detail [stock]"
        html_information={ true }
				information={tradeDetail} />
			
			<MODAL
        open={ showVolumePriceBasedInfo }
        setOpen={() => { updateShowVolumePriceBasedInfo(false); }}
        headline="Volume Price Based Sentiment"
        html_information={ false }
				information={`Volume price based sentiment is an abstract indicator. It's common that platforms will try to reflect sentiment of a stock trade by "matching," the trade to the proceeding bid or ask. The gap in this type of method is that it's common to use Nation Best Bid Offer (NBBO) as the quote to match to.`} />
			
			<SHARE_TRADE_ORDER_MODAL
				open={showShareTradeModal}
				data={orderTradeData}
				setOpen={() => { updateOrderTradeData(null); updateShowShareTradeModal(false); }}
				/>

    </div> 
  );
}
export default TICKER_IMMEDIATE_DATA_WITH_VOLUME_MODAL;
